@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
*{
    font-family: "Montserrat", sans-serif;
    scroll-behavior: smooth;
}

@media only screen and (max-width: 768px){
  footer .follow{
      text-align: -webkit-center;
  }
  footer .navi a{
      text-align: center;
  }
}
@media only screen and (max-width: 400px){
  .para-container{
      position: unset !important;
  }
  .full-para{
      margin-left: 0;
      margin-right: 0;
  }
}

.dark-bg{
    background-color: #0b2239 !important;
}

.dark-bg .title{
    color: white;
}

.modal{
    height: -webkit-fill-available;
}
.modal .modal-dialog{
    height: -webkit-fill-available;
}
.modal .modal-dialog .modal-content{
    height: -webkit-fill-available;
}
.modal .modal-dialog .modal-content .modal-body{
    height: 100%;
}
.modal .modal-dialog .modal-content .modal-body iframe{
    height: 100%;
    background-image: url("/src/images/Animation\ -\ 1731310376081.gif");
    background-repeat: no-repeat;
    background-position: center;
}